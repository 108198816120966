import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedinIn, FaFacebookF, FaInstagram } from "react-icons/fa";

const links = [
  { name: "home", href: "/" },
  { name: "about", href: "about-us" },
  { name: "contact", href: "contact" },
  { name: "faq", href: "frequently-asked-questions" },
  { name: "services", href: "services" },
  { name: "forms", href: "forms" },
];

function Footer() {
  return (
    <div className="bg-[#213364] text-[rgba(255,255,255,0.8)]">
      {/* grid starts */}
      <div className="container mx-auto pt-14 pb-6 px-5 lg:px-0">
        <div className="grid lg:grid-cols-6 gap-5 space-y-10 lg:space-y-0">
          {/* grid-1 */}
          <div className="col-span-2">
            <p className="text-lg text-white font-medium">About</p>
            <p className="text-sm mt-4 font-light leading-6">
              CK Tax has been in the accounting industry for over 3 decades. We
              have established an excellent track record of providing
              exceptional tax preparation and consultation services at the most
              affordable rates. We guarantee the utmost professionalism and
              dedication in all our ventures.
            </p>
            {/* <p className="uppercase text-sm text-white my-4 font-normal">
              download our app
            </p>
            <div className="flex items-center space-x-5">
              <Link
                to={
                  "https://play.google.com/store/apps/details?id=com.maurice.mmtax"
                }
                target={"_blank"}
              >
                <img src={require("../../assets/gply.png")} alt="" />
              </Link>
              <Link
                to={"https://apps.apple.com/us/app/id1548484947"}
                target={"_blank"}
              >
                <img src={require("../../assets/appstore.png")} alt="" />
              </Link>
            </div> */}
          </div>

          {/* grid-2 */}
          <div className="col-span-2">
            <p className="text-lg text-white font-medium">Our Locations</p>
            <div className="space-y-4 mt-4 text-sm">
              <p className="font-semibold text-white">Bothwell</p>
              <p>30395 W Bothwel Rd, Bothwell, ON NOP 1C0</p>
              <p className="font-semibold text-white">Windsor</p>
              <p>2115 Pelissier St, Windsor, ON N8X 1N3</p>
            </div>
          </div>

          {/* grid-3 */}
          <div className="col-span-1">
            <p className="text-lg text-white font-medium">Quick Links</p>
            <div className="flex mt-4 flex-col text-sm space-y-3">
              {links.slice(0, 5).map((item) => (
                <Link to={item.href} className="hover:text-white capitalize">
                  {item.name}
                </Link>
              ))}
            </div>
          </div>

          {/* grid-4 */}
          <div className="col-span-1">
            <div className="flex mt-12 flex-col text-sm space-y-3">
              <Link to="/us-tax-returns-checklist" className="hover:text-white">
                US Tax Returns Checklist
              </Link>
              <Link to="/canada-personal-tax-return-checklist" className="hover:text-white">
                Canada Tax Return Checklist
              </Link>
              <Link to="/forms" className="hover:text-white">
                Forms
              </Link>
            </div>
          </div>
        </div>
        {/* copyright section */}
        <div className="md:flex items-center justify-between mt-20 text-center text-sm">
          <p>
            &copy; {new Date().getFullYear()} CK Tax - All Rights Reserved |{" "}
            <Link to={"/"} className="hover:text-white">
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link to={"/"} className="hover:text-white">
              Terms & Conditions
            </Link>
          </p>
          {/* social links */}
          <div className="flex items-center justify-center lg:justify-end space-x-4 mt-5 lg:mt-0">
            <Link
              to={"/"}
              className="opacity-50 text-white hover:opacity-100"
              target={"_blank"}
            >
              <FaLinkedinIn size={20} />
            </Link>
            <Link
              to={"/"}
              className="opacity-50 text-white hover:opacity-100"
              target={"_blank"}
            >
              <FaFacebookF size={20} />
            </Link>
            <Link
              to={"/"}
              className="opacity-50 text-white hover:opacity-100"
              target={"_blank"}
            >
              <FaInstagram size={20} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
