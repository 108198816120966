import React, { lazy, Suspense, useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import Home from "./screens/Home";

import NotFound from "./components/notfound/NotFound";
import Thankyou from "./components/thanks/Thankyou";

const About = lazy(() => import("./screens/About"));
const Faq = lazy(() => import("./screens/Faq"));
const Contact = lazy(() => import("./screens/Contact"));
const FormPage = lazy(() => import("./screens/Form"));
const Services = lazy(() => import("./screens/Services"));
const Login = lazy(() => import("./screens/auth/Login"));
const Leads = lazy(() => import("./screens/admin/Leads"));
const Personal = lazy(() => import("./screens/services/Personal"));
const Corporate = lazy(() => import("./screens/services/Corporate"));
const Nonresident = lazy(() => import("./screens/services/Nonresident"));
const CrossBorder = lazy(() => import("./screens/services/CrossBorder"));
const UsTaxes = lazy(() => import("./screens/services/UsTaxes"));
const Trust = lazy(() => import("./screens/services/Trust"));
const Bookeeping = lazy(() => import("./screens/services/Bookeeping"));
const Usreturns = lazy(() => import("./screens/returns/Usreturns"));
const Careturns = lazy(() => import("./screens/returns/Usreturns"));

function App() {
  const { currentUser } = useContext(AuthContext);

  const ProtectedRoute = ({ children }) => {
    return currentUser ? children : <Navigate to="/" />;
  };

  const ScrollTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  return (
    <>
      <ToastContainer />
      <ScrollTop />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path={"/"} element={<Home />} />

          <Route path={"about-us"} element={<About />} />
          <Route path={"services"} element={<Services />} />
          <Route path={"forms"} element={<FormPage />} />
          <Route path={"frequently-asked-questions"} element={<Faq />} />
          <Route path={"contact"} element={<Contact />} />
          <Route path={"*"} element={<NotFound />} />
          <Route path={"thank-you"} element={<Thankyou />} />

          {/* services */}
          <Route
            path={"services/personal-income-tax-returns"}
            element={<Personal />}
          />
          <Route
            path={"services/corporate-income-tax-returns"}
            element={<Corporate />}
          />
          <Route
            path={"services/non-resident-taxes"}
            element={<Nonresident />}
          />
          <Route
            path={"services/cross-border-taxes"}
            element={<CrossBorder />}
          />
          <Route
            path={"services/us-federal-and-state-taxes"}
            element={<UsTaxes />}
          />
          <Route
            path={"services/trust-taxes-for-deceased-individuals"}
            element={<Trust />}
          />
          <Route path={"book-keeping"} element={<Bookeeping />} />
          <Route path={"us-tax-returns-checklist"} element={<Usreturns />} />
          <Route
            path={"canada-personal-tax-return-checklist"}
            element={<Careturns />}
          />

          <Route path={"admin"} element={<Login />} />
          <Route
            path={"admin/leads"}
            element={
              <ProtectedRoute>
                <Leads />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
