import React from "react";
import { useCountUp } from "use-count-up";
// import { easingMap } from "./easingMap";

function CountUp({ start, end }) {
  const { value } = useCountUp({
    isCounting: true,
    start: start,
    end: end,
    duration: 2,
    easing: "easeOutCubic",
  });
  return <>{value}</>;
}

export default CountUp;
