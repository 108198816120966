export const services_offered = [
  {
    _id: 1,
    link: "/",
    title: "Accounting Services",
    desc: "We specialize in managing your personal income taxes with utmost efficiency and enthusiasm. Give us a call to know more!",
    img: require("../assets/h14.png"),
  },
  {
    _id: 2,
    link: "/",
    title: "Cross Border Taxes",
    desc: "We have specialists who have decades of experience working with Big 4 consulting firms bringing in the experience of international tax matters including cross border tax issues. Get in touch with us right away to know more!",
    img: require("../assets/h16.png"),
  },
  {
    _id: 3,
    link: "/",
    title: "US Taxes",
    desc: "Our tax experts have experience preparing everything from basic US income tax returns to highly complex multi-state individual income tax returns. We are able to e-file US tax returns in our Canadian office where applicable. Give us a call today to know more!",
    img: require("../assets/h19.png"),
  },
  {
    _id: 4,
    link: "/",
    title: "Corporate Income Taxes",
    desc: "Our very efficient tax consultants have a reputation for providing the best in class corporate income tax support. Call us today to know more!",
    img: require("../assets/h15.png"),
  },
  {
    _id: 5,
    link: "/",
    title: "Non-Resident Taxes",
    desc: "We have set a benchmark for ourselves in providing consultation and assistance concerning non-resident taxes. Contact us today to know more!",
    img: require("../assets/h18.png"),
  },
  {
    _id: 6,
    link: "/",
    title: "Death and Taxes",
    desc: "Our tax professionals are experienced in providing tax preparation services for the trust/estates along with the Final Taxes and clearance certificates. Contact us today to know more!",
    img: require("../assets/h20.png"),
  },
];
