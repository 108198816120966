import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { services_offered } from "../constants";
import CountUp from "../components/countup/CountUp";
import Header from "../components/Header/Header";
import FileTaxing from "../components/popup/FileTaxing";

//
import Reviews from "../components/reviews/Reviews";

function Home() {
  return (
    <>
      <div className="absolute top-0 left-0 right-0 z-10">
        <Header />
        <Banner />
      </div>
      {/* <div className="fixed bottom-14 animate-bounce duration-700 ease-linear left-5 z-10">
        <WhatsAppSvg />
      </div> */}
      <FileTaxing />
      <div className="relative">
        <div className="bg-black h-[600px] lg:h-screen overflow-hidden">
          <img
            src={require("../assets/h1.jpg")}
            alt=""
            className="w-full h-full object-cover opacity-50"
          />
        </div>
      </div>
      <About />
      <Options />
      {/* <ClientsCount /> */}
      <Founder />
      <Reviews />
      <Service />
      <GetInTouch />
      <Footer />
    </>
  );
}

const Banner = () => {
  return (
    <div className="container mx-auto px-6 lg:px-0">
      <div className="grid grid-cols-2 my-44 xl:my-52">
        <div className="col-span-1 text-3xl xl:text-6xl font-medium space-y-3">
          <p className="text-white">Highly experienced</p>
          <p className="text-white">Tax consultants</p>
          <p className="text-[#23a7e1]">in Chatham-Kent Region</p>
        </div>
      </div>
      <div className="flex items-center justify-center pb-3 animate-bounce duration-300">
        <a href={"#about"}>
          <IoIosArrowDown size={28} className="text-white" />
        </a>
      </div>
    </div>
  );
};

// const Header = () => {
//   return (
//     <>
//       <div className="bg-transparent absolute inset-0">
//         {/* desktop menu */}
//         <header className="relative hidden lg:block">
//           <nav
//             aria-label="Top"
//             className="mx-auto container px-4 sm:px-6 lg:px-8"
//           >
//             <div className="flex h-28 items-center">
//               {/* Logo */}
//               <div className="ml-4 flex lg:ml-0">
//                 <Link to={"/"}>
//                   <span className="sr-only">CK Tax and Accounting</span>
//                   <img
//                     className="h-20 w-auto"
//                     loading="lazy"
//                     src={require("../assets/CK-Tax-03.png")}
//                     alt=""
//                   />
//                 </Link>
//               </div>
//             </div>
//           </nav>

//           <div className="absolute top-0 right-0 bg-yellow-500">
//             <p>Home</p>
//           </div>
//         </header>
//       </div>
//     </>
//   );
// };

const Options = () => {
  return (
    <div className="bg-[#f0f2f8] py-28 px-6 xl:px-0">
      <div className="container mx-auto">
        <p className="uppercase text-[rgba(33,51,100,0.6)] text-sm font-semibold">
          what we do
        </p>
        <p className="text-[#1d3a6d] text-4xl mt-16 mb-12">
          Services from CK Tax.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="col-span-1 space-y-8">
            {/* set1 */}
            <div className="flex items-center justify-start space-x-8 ">
              <img src={services_offered[0].img} className="h-20" alt="" />
              <Link
                to={services_offered[0].link}
                className="max-w-md space-y-2 overflow-hidden duration-300 hover:-translate-y-2"
              >
                <p className="font-bold text-[#1D3A6D]">
                  {services_offered[0].title}
                </p>
                <p className="leading-6 text-sm font-semibold text-gray-600">
                  {services_offered[0].desc}
                </p>
              </Link>
            </div>

            {/* set2 */}
            <div className="flex items-center justify-start space-x-8 ">
              <img src={services_offered[1].img} className="h-20" alt="" />
              <Link
                to={services_offered[1].link}
                className="max-w-md space-y-2 overflow-hidden duration-300 hover:-translate-y-2"
              >
                <p className="font-bold text-[#1D3A6D]">
                  {services_offered[1].title}
                </p>
                <p className="leading-6 text-sm font-semibold text-gray-600">
                  {services_offered[1].desc}
                </p>
              </Link>
            </div>

            {/* set3 */}
            <div className="flex items-center justify-start space-x-8 ">
              <img src={services_offered[2].img} className="h-20" alt="" />
              <Link
                to={services_offered[2].link}
                className="max-w-md space-y-2 overflow-hidden duration-300 hover:-translate-y-2"
              >
                <p className="font-bold text-[#1D3A6D]">
                  {services_offered[2].title}
                </p>
                <p className="leading-6 text-sm font-semibold text-gray-600">
                  {services_offered[2].desc}
                </p>
              </Link>
            </div>
          </div>
          <div className="col-span-1 space-y-8">
            {/* set4 */}
            <div className="flex items-center justify-start space-x-8 ">
              <img src={services_offered[3].img} className="h-20" alt="" />
              <Link
                to={services_offered[3].link}
                className="max-w-md space-y-2 overflow-hidden duration-300 hover:-translate-y-2"
              >
                <p className="font-bold text-[#1D3A6D]">
                  {services_offered[3].title}
                </p>
                <p className="leading-6 text-sm font-semibold text-gray-600">
                  {services_offered[3].desc}
                </p>
              </Link>
            </div>

            {/* set5 */}
            <div className="flex items-center justify-start space-x-8 ">
              <img src={services_offered[4].img} className="h-20" alt="" />
              <Link
                to={services_offered[4].link}
                className="max-w-md space-y-2 overflow-hidden duration-300 hover:-translate-y-2"
              >
                <p className="font-bold text-[#1D3A6D]">
                  {services_offered[4].title}
                </p>
                <p className="leading-6 text-sm font-semibold text-gray-600">
                  {services_offered[4].desc}
                </p>
              </Link>
            </div>

            {/* set6 */}
            <div className="flex items-center justify-start space-x-8 ">
              <img src={services_offered[5].img} className="h-20" alt="" />
              <Link
                to={services_offered[5].link}
                className="max-w-md space-y-2 overflow-hidden duration-300 hover:-translate-y-2"
              >
                <p className="font-bold text-[#1D3A6D]">
                  {services_offered[5].title}
                </p>
                <p className="leading-6 text-sm font-semibold text-gray-600">
                  {services_offered[5].desc}
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Founder = () => {
  return (
    <div className="founder">
      <div className="grid grid-cols-1 md:grid-cols-5 gap-5 px-6 xl:px-0 container mx-auto py-14">
        <div className="col-span-3">
          <p className="text-[#ccbd9b] font-semibold tracking-wider text-xs mb-9">
            YOU’RE IN GOOD COMPANY
          </p>
          <p className="text-xl lg:text-5xl text-[#1d3a6d] mb-8">
            A message from the Managing partners
          </p>
          <p className="leading-9">
            At <strong>CK Tax</strong>, we value your hard-earned money and
            understand the criticality of tax savings. We give our 100% to be on
            your side by assisting you with our taxation and accounting
            services. No matter how complex and complicated your issues are, we
            have competent staff to handle and resolve the tax matter in a fair
            and professional manner. Your trust in us will be complemented with
            prompt responses through customer service, maximized tax savings,
            and most importantly your business and financial growth. If you ever
            feel ambiguity with regards to your tax matters be it notice, audit,
            collections, we are here to listen to you and tailor our service to
            represent you with the tax authorities.
          </p>
        </div>
        <div className="col-span-2">
          <img
            src={require("../assets/shekhar.png")}
            className="h-full w-full object-cover mx-auto"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const Service = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:h-[500px]">
      <div className="col-span-1 overflow-hidden">
        <img
          src={require("../assets/marah.jpg")}
          className="w-full h-full object-cover"
          alt=""
        />
      </div>
      <div className="bg-[#212932] p-14 lg:p-24 col-span-1 space-y-6">
        <p className="text-white text-xl lg:text-5xl">
          39 Years in Canadian's Tax Consulting Services
        </p>
        <p className="text-[#e6e6e6] leading-7">
          We have been providing a quality service to our customers with a motto
          to reduce the ambiguity in our clients about the Canadian and US tax
          system. At CK Tax, we love taxes. We are experienced to deal with any
          type of tax issue that may arise in your personal, corporate or
          foreign tax matters.
        </p>
        <p className="text-[#e6e6e6] leading-7">
          We serve a wide variety of clients located mainly in both Canada and
          the United States. Our clients maintain strong and open relationship
          with CK Tax due to our commitment to quality and responsiveness.
        </p>
      </div>
    </div>
  );
};

const GetInTouch = () => {
  return (
    <div className="bg-touch bg-no-repeat bg-cover bg-center bg-fixed">
      <div className="grid lg:grid-cols-2 gap-5 max-w-7xl mx-auto py-48 px-5 xl:px-0 text-white space-y-8 lg:space-y-0">
        <div className="col-span-1">
          <div className="max-w-lg">
            <p className="text-5xl  font-semibold">Get in touch</p>
            <p className="font-bold text-sm mt-7 mb-10 leading-7">
              We are the most trusted and preferred consultants for over 39
              years in Canada. We are now available in Toronto to help people
              with personal tax issues, real estate taxes, cross-border tax
              implications, regular filings, etc. Contact us today to leave your
              taxation and accounting worries behind!.
            </p>
            <Link
              to={"/contact"}
              className="bg-gray-100 text-black text-xs font-semibold rounded px-6  py-3.5 shadow-sm uppercase"
            >
              connect with us
            </Link>
          </div>
        </div>

        {/* grid 2 */}
        <div className="col-span-1">
          <div className="flex items-center lg:justify-end">
            <div className="bg-[#18568e] py-20 px-16 max-w-full lg:max-w-md w-full">
              <p className="capitalize text-white font-semibold tracking-wide text-lg mb-10">
                our office Locations
              </p>
              <Link
                to={
                  "https://www.google.com/maps/place/C+K+Tax+%26+Accounting/@42.6318454,-81.879554,15z/data=!4m2!3m1!1s0x0:0x9138cc1086947cdd?sa=X&ved=2ahUKEwiCxa3p0sL9AhX6lGoFHbAoAykQ_BJ6BAhREAc"
                }
                target={"_blank"}
                className="font-semibold tracking-wider"
              >
                <div className="flex items-center justify-between">
                  <p className="hover:translate-x-2 duration-300">Bothwell</p>
                  <IoIosArrowForward />
                </div>
              </Link>
              <p className="bg-gray-400 h-[1px] w-full my-4"></p>
              <Link
                to={
                  "https://www.google.com/maps/place//data=!4m2!3m1!1s0x883b2c5743e5dd27:0xdfbd1de9516a73ae?source=g.page.default"
                }
                target={"_blank"}
                className="font-semibold tracking-wider"
              >
                <div className="flex items-center justify-between">
                  <p className="hover:translate-x-2 duration-300">Windsor</p>
                  <IoIosArrowForward />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ClientsCount = () => {
  return (
    <div className="bg-[#1d3a6d] text-white py-16 ">
      <div className="grid grid-cols-1 space-y-5 lg:space-y-0 lg:grid-cols-3 gap-5 container mx-auto">
        <div className="col-span-1 flex items-center justify-center flex-col space-y-4">
          <p className="lg:text-7xl text-2xl font-semibold">
            <CountUp start={0} end={39} />
          </p>
          <p className="font-semibold">Years of Experience</p>
          <svg
            style={{ height: 40 }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            enable-background="new 0 0 64 64"
          >
            <g
              fill="none"
              stroke="#fff"
              stroke-width="2"
              stroke-miterlimit="10"
            >
              <path d="m63 3v50l-4 8-4-8v-50z"></path>
              <path d="m55 7h-4v10"></path>
              <path d="m4 3h40v58h-40z"></path>
              <path d="m34 3v57"></path>
              <path d="m8 16h-8"></path>
              <path d="m8 8h-8"></path>
              <path d="m8 24h-8"></path>
              <path d="m8 32h-8"></path>
              <path d="m8 40h-8"></path>
              <path d="m8 48h-8"></path>
              <path d="m8 56h-8"></path>
            </g>
          </svg>
        </div>
        <div className="col-span-1 flex items-center justify-center flex-col space-y-4">
          <p className="text-2xl lg:text-7xl font-semibold">
            <CountUp start={0} end={30} />k
          </p>
          <p className="font-semibold">Happy Clients</p>
          <svg
            height={40}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            enable-background="new 0 0 64 64"
          >
            <g
              fill="none"
              stroke="#fff"
              stroke-width="2"
              stroke-miterlimit="10"
            >
              <path d="m24 30c0 4.418 3.582 8 8 8s8-3.582 8-8"></path>
              <path d="m18 20h2"></path>
              <path d="m46 20h-2"></path>
              <path d="m32 47h31v-42h-62v42h17v12z"></path>
            </g>
          </svg>
        </div>
        <div className="col-span-1 flex items-center justify-center flex-col space-y-4">
          <p className="text-2xl lg:text-7xl font-semibold">
            <CountUp start={0} end={200} />k
          </p>
          <p className="font-semibold">Tax Returns Filed</p>
          <svg
            height={40}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            enable-background="new 0 0 64 64"
          >
            <g
              fill="none"
              stroke="#fff"
              stroke-width="2"
              stroke-miterlimit="10"
            >
              <path d="m23 41l-1 5 5-1 15-15-4-4z"></path>
              <path d="m39 33l-4-4"></path>
              <path d="m23 8h-13v55h44v-55h-13"></path>
              <path d="m36 5v-4h-8v4h-4l-2 8h20l-2-8z"></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

const About = () => {
  return (
    <>
      <div
        className="grid grid-cols-1 md:grid-cols-3 md:gap-5 lg:gap-36 container mx-auto py-16 px-6 xl:px-0 space-y-4 md:space-y-0"
        id="about"
      >
        <div className="col-span-1">
          <p className="text-[#1d3a6d] xl:text-4xl text-xl font-light">
            "Now at bothwell"
          </p>
        </div>
        <div className="col-span-2">
          <p className="leading-7">
            Throughout the years, we have always gone the extra mile to achieve
            100% client satisfaction in every service we provide. We support,
            guide, and assist our clients in handling their financial and
            tax-related matters. Irrespective of the size and the nature of your
            business, we are more than capable of assisting you with any tax
            issue that arises.
          </p>
        </div>
      </div>
      <div className="bg-[#18568e] py-20">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-6 xl:px-0">
            <div className="col-span-1">
              <p className="uppercase text-[rgba(255,255,255,0.6)] text-xs font-bold">
                about
              </p>
              <p className="text-white text-xl xl:text-[37px] xl:leading-normal font-medium xl:tracking-wide mt-14">
                CK Tax has been operating in the Windsor community for more than
                3 decades.
              </p>
              <p className="mt-5 text-gray-200 leading-7">
                Here at CK Tax we aim to provide world class tax preparation
                services at the most affordable rates. Since the beginning, our
                vision has remained constant, to provide practical solutions,
                and quality customer service.
              </p>
              <p className="mt-5 text-gray-200 leading-7">
                Throughout the years, we have always gone the extra mile to
                achieve 100% client satisfaction in every service we provide. We
                support, guide, and assist our clients in handling their
                financial and tax-related matters. Irrespective of the size and
                the nature of your business, we are more than capable of
                assisting you with any tax issue that arises.
              </p>
              <p className="mt-5 mb-10 text-gray-200">
                So, what are you waiting for? Get in touch with us right away
                for tax preparation and consultation services!
              </p>
              <Link
                to={"/about-us"}
                className="font-semibold uppercase text-xs text-gray-200"
              >
                <div className="flex items-center">
                  Read More <IoIosArrowForward className="ml-3" />
                </div>{" "}
              </Link>
            </div>
            <div className="col-span-1">
              <img
                src={require("../assets/h3.png")}
                className="h-96 lg:h-full mx-auto"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
