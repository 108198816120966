import React from "react";
import { HiStar } from "react-icons/hi";
import { IoCaretDownOutline } from "react-icons/io5";

const reviews = [
  {
    name: "Shawn C",
    desc: "CK Tax is the best! Very professional, extremely supportive and will always take the time to answer your questions and make you feel like a valued client. CK Tax always follows through as promised.",
  },
  {
    name: "Trevor Perkin",
    desc: "Sandra is very professional and great to work with!",
  },
  {
    name: "Tanya Oakley",
    desc: "Great place to do your books and taxes. Will recommend to anyone.",
  },
  {
    name: "Trish Pedersen",
    desc: "Efficient work and very personable",
  },
];

function Reviews() {
  return (
    <div className="py-14 px-5 xl:px-0">
      <img
        src={require("../../assets/quote-1.png")}
        className="mx-auto h-20"
        alt=""
      />
      <div className="max-w-3xl mx-auto">
        <p className="mt-14 text-center xl:text-5xl">CK Tax</p>
        <div className="mx-auto">
          <div className="flex items-center justify-center mt-5 space-x-2">
            <p className="text-2xl font-semibold text-[#F7B603]">4.9</p>
            <div className="flex items-center">
              <HiStar className="h-7 w-7  text-[#F7B603]" />
              <HiStar className="h-7 w-7  text-[#F7B603]" />
              <HiStar className="h-7 w-7  text-[#F7B603]" />
              <HiStar className="h-7 w-7  text-[#F7B603]" />
              <HiStar className="h-7 w-7  text-[#F7B603]" />
              {/* <HiOutlineStar className="h-6 w-6  text-[#F7B603]"/> */}
            </div>
            <p className="font-medium">7 reviews</p>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-10">
          {reviews.map((item) => (
            <div className="col-span-1" key={item.name}>
              <div className="relative">
                <div className="bg-[#1F3B78] text-white text-center font-medium px-5 py-2.5 text-sm rounded-lg h-24">
                  <p>
                    {item.desc.length > 127
                      ? item.desc.slice(0, 127) + "..."
                      : item.desc}
                    {/* {item.desc.length > 127 && <span onClick={() => set}>more</span>} */}
                  </p>
                </div>
                <div className="absolute -bottom-3 left-1/2 right-1/2">
                  <IoCaretDownOutline size={20} />
                </div>
              </div>
              <p className="font-bold text-base text-center mt-4">
                {item.name}
              </p>
              <div className="flex items-center justify-center">
                <HiStar className="h-4 w-4  text-[#F7B603]" />
                <HiStar className="h-4 w-4  text-[#F7B603]" />
                <HiStar className="h-4 w-4  text-[#F7B603]" />
                <HiStar className="h-4 w-4  text-[#F7B603]" />
                <HiStar className="h-4 w-4  text-[#F7B603]" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Reviews;
